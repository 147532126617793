import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { PropertyValue } from '../../shared/model/propertyvalue';
import { Document } from '../../shared/model/document';
import { DocumentService } from '../../shared/services/document.service';
import { ToastrService } from 'ngx-toastr';
import { RequestService } from '../../shared/services/request.service';
import { ElkService } from '../../shared/services/elk.service';
import { SearchHit } from '../../shared/model/searchResult';
import { Dossier } from '../../shared/model/dossier';
import { DossierService } from '../../shared/services/dossier.service';
import { FileUploadService } from '../../shared/services/file-upload.service';
import { MatDialog } from '@angular/material/dialog';
import { SendFileComponent } from '../send-file/send-file.component';
import { MailMessage } from '../../shared/model/mail.message';
import { FileUploader } from 'ng2-file-upload';
import { getConfigFileParsingDiagnostics } from 'typescript';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChatService } from '../../shared/services/chat.service';
import { AuthService } from '../../shared/services/auth.service';
import { getMatFormFieldDuplicatedHintError } from '@angular/material/form-field';
import { LinkDocumentComponent } from '../link-document/link-document.component';
import { MatTableDataSource } from '@angular/material/table';
const URL = 'https://httpbin.org/post';

export interface TypeLink {
  destinationParentId: string;
  destinationParentName: string;
}

@Component({
  selector: 'app-object-data-detail',
  templateUrl: './object-data-detail.component.html',
  styleUrls: ['./object-data-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ObjectDataDetailComponent implements OnInit {
  @Input("meta") curMeta: JSON;
  @Input("cols") columns: JSON[];
  @Input() dossier: Dossier;
  @Output() removeEvent: EventEmitter<any> = new EventEmitter();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  edit: boolean = false;
  document: Document;
  propertyValue: PropertyValue;
  public objectForm: FormGroup;
  supplierEmail: string;

  form: FormGroup;
  progress: number = 0;
  public docID: string;
  public files: FileList;
  public index: string;
  @Input("hit") curHit: SearchHit;
  viewer: string = null;

  public fileUrl;
  public fileBlob;
  private doc: JSON;
  uploading: boolean = false;
  fileSize: any;
  errorMessage: string;
  disable: string;
  sizeError: boolean;
  panelOpenState: boolean = false;
  customerEmail: string;
  messages: any = [];
  Formulas: string[] = ['@Supplier', '@Name'];
  brothers: Dossier[];
  links: TypeLink[] = [];
  dataLink: any[] = [];
  constructor(private fb: FormBuilder, private documentService: DocumentService,
    private toastrService: ToastrService,
    private requestService: RequestService,
    private dossierService: DossierService,
    private elkService: ElkService,
    private chatService: ChatService,
    private authService: AuthService,
    private fileUploadService: FileUploadService,
    public dialog: MatDialog) {
    this.form = this.fb.group({
      name: [''],
      avatar: [null]
    })

  }
  public config: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null
  };
  ngOnInit(): void {
    this.document = new Document(this.dossierService.currentDossierId);

    this.files = null;
    this.docID = this.curMeta["documentuniqueid"];
    let linkMapped = this.curMeta["links"].map(l => {
      let link = { destinationParentId: l.destinationParentId, destinationParentName: l.destinationParentName };
      return link;
    });
    this.links = this.getUniqueListBy(linkMapped, 'destinationParentId');
    console.log("iniqLinks", this.links)
    this.index = this.curMeta["_index"];
    this.getFile();
    this.initForm();
    this.documentService.getMessagesByIdDocument(this.docID)
      .subscribe(resp => {
        this.messages = resp;
        this.chatService.chat.push({ id: this.authService.user.email, message: this.messages })
      });



  }
  getUniqueListBy(arr: any[], key: any) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  getAllData(link: TypeLink) {
    console.log("link ====> ", link);
    this.documentService.getAllDocumentLinked(this.docID, link.destinationParentId)
      .subscribe({
        next: (sortie) => {
          console.log("sortie ==>", sortie)
          this.curMeta["linkDetails"][sortie["title"]] =
          {
            displayedColumns: [],
            columns: sortie["header"],
            dataSource: new MatTableDataSource<any>(sortie["data"])
          }
            ;
          for (var i = 0; i < sortie["header"].length; i++) {
            if (sortie["header"][i].visibleOnList) {
              this.curMeta["linkDetails"][sortie["title"]]["displayedColumns"].push(sortie["header"][i].prop);
            }
          }

        },
        error: (err) => {

        }
      })
  }

  getFile() {
    this.elkService.getFile(this.index, this.docID).subscribe(res => {
      if (res == null) {
        this.viewer = "error";
      } else {
        this.doc = res;
        this.fileBlob = this.fileUploadService.dataURItoBlob(this.doc["_source"]["data"], this.doc["_source"]["attachment"]["content_type"]);
        this.fileUrl = window.URL.createObjectURL(this.fileBlob);
        if (this.doc["_source"]["attachment"]["content_type"] == "application/pdf")
          this.viewer = "pdf";
        else
          this.viewer = "docx";
      }
    },
      error => {
        this.viewer = "error";
      })
  }
  modifier() {
    this.edit = !this.edit;
    //console.log(this.objectForm.controls);
  }
  downloadFile() {
    window.open(this.fileUrl);
    //this.file = new File([this.fileBlob], this.fileName, { type: "application/pdf" });
  }

  save() {
    this.document.documentuniqueid = this.curMeta["documentuniqueid"];
    for (var i = 0; i < this.columns.length; i++) {
      this.document.properties.push(new PropertyValue(
        this.curMeta[this.columns[i]["prop"]][0]["id"],
        this.objectForm.value[
        this.columns[i]["prop"] + '_'
        + this.curMeta[this.columns[i]["prop"]][0]["id"]
        ], this.columns[i]["propertyId"]
      ));
    }
    this.documentService.update(this.document).subscribe(res => {
      this.toastrService.success("Document sauvegardé avec succès.");
      this.refreshList.emit();
    });
    //this.document.properties.push(new PropertyValue(null,this.objectForm.value[i].,);
  }
  public onUploadInit(args: any): void { }
  close() {

    this.removeEvent.emit(this.curMeta);
  }
  public initForm() {
    this.objectForm = new FormGroup({});
    var formControl: FormControl;
    // var jsonString: string = '{';
    var sep = "";
    var validators: ValidatorFn[] = [];
    for (var i = 0; i < this.columns.length; i++) {
      validators.splice(0, validators.length);
      if (this.columns[i]["required"]) {
        validators.push(Validators.required);
      }
      if (this.columns[i]["hasmin"]) {
        validators.push(Validators.min(this.columns[i]["minvalue"]));
      }
      if (this.columns[i]["hasmax"]) {
        validators.push(Validators.max(this.columns[i]["maxvalue"]));
      }

      if (this.columns[i]["pattern"] && this.Formulas.indexOf(this.columns[i]["pattern"]) < 0) {
        validators.push(Validators.pattern(this.columns[i]["pattern"]));
      }
      if (this.columns[i]["type"] == "email") {
        validators.push(Validators.email);
      }
      formControl = new FormControl(this.curMeta[this.columns[i]["prop"]][0]["value"], validators);
      this.objectForm.addControl(this.columns[i]["prop"] + '_'
        + this.curMeta[this.columns[i]["prop"]][0]["id"], formControl);

      if (this.columns[i]["type"] == 'text' && this.columns[i]["name"] == 'Email') {
        this.customerEmail = this.columns[i]["prop"] + '_'
          + this.curMeta[this.columns[i]["prop"]][0]["id"];
      }
    }
  }


  public onUploadError(args: any): void { alert("Echec upload - Reessayer.") }

  public onUploadSuccess(args: any): void {
    this.uploadFile();
  }

  public uploadFile_old() {
    if (this.docID === '') {
      alert("Définir l'id du document.");
      return;
    }
    if (this.files == null) {
      alert("Aucun fichier sélectionné.");
      return;
    }
    let file = this.files[0];
    if (file.size > 5120000) {
      this.sizeError = true;
      this.errorMessage = "Le fichier que vous avez choisi dépasse la taille maximale autorisée (5Mo).";
    } else {
      this.uploading = true;
      this.fileToBase64(file)
        .then(result => {
          this.elkService.uploadFile(result, this.index, this.docID)
            .then(succes => {
              this.getFile();
              this.toastrService.success("Le document " + this.docID + " a été bien uploadé.");
              this.uploading = false;
              this.files = null;
            })
            .catch(err => console.error("Upload KO"));
        }).catch(error => {
          this.toastrService.error("Une erreur s'est produite lors du chargement du fichier : " + error);
          this.uploading = false;
        });
    }

  }

  public uploadFile() {
    if (this.docID === '') {
      alert("Définir l'id du document.");
      return;
    }
    if (this.files == null) {
      alert("Aucun fichier sélectionné.");
      return;
    }
    let file = this.files[0];
    if (file.size > 5120000) {
      this.sizeError = true;
      this.errorMessage = "Le fichier que vous avez choisi dépasse la taille maximale autorisée (5Mo).";
    } else {
      this.uploading = true;
      this.fileToBase64(file)
        .then(result => {
          this.elkService.uploadFile2(result, this.index, this.docID)
            .subscribe((event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  console.log('Request has been made!');
                  break;
                case HttpEventType.ResponseHeader:
                  console.log('Response header has been received!');
                  break;
                case HttpEventType.UploadProgress:
                  this.progress = Math.round(event.loaded / event.total * 100);
                  console.log(`Uploaded! ${this.progress}%`);
                  break;
                case HttpEventType.Response:
                  console.log('Document successfully created!', event.body);
                  setTimeout(() => {
                    this.progress = 0;
                    this.getFile();
                    this.toastrService.success("Le document " + this.docID + " a été bien uploadé.");
                    this.refreshList.emit();
                  }, 1500);

              }
            })
        }).catch(error => {
          this.toastrService.error("Une erreur s'est produite lors du chargement du fichier : " + error);
          this.uploading = false;
        });
    }

  }


  public selectFile(event) {
    this.files = event.srcElement.files;
    this.fileSize = this.files[0].size;
    this.errorMessage = "";
    this.sizeError = false;
  }

  private fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => resolve(btoa(reader.result.toString()));
      reader.onerror = error => reject(error);
    });
  }


  openLinkDocument() {

    this.dossierService.getDossierBrothers(this.curMeta["documentuniqueid"]).subscribe(res => {
      let dialogRef = this.dialog.open(LinkDocumentComponent, {
        data: { "data": res, "id": this.docID },
        disableClose: true,
        maxWidth: '60vw',
        maxHeight: '80vh',
        height: '100%',
        width: '100%',
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });


    },
      error => {
        this.toastrService.error("Une erreur s'est produite lors de la récupération des liens", error)
      });




  }

  sendFile() {
    var newMail: MailMessage = new MailMessage();
    newMail.from = this.authService.user.email;
    newMail.to = this.objectForm.value[this.customerEmail];
    newMail.fileToSend = null;
    newMail.filename = null;
    newMail.subject = "VUSADOC: application magique";

    let dialogRef = this.dialog.open(SendFileComponent, {
      data: newMail //A revoir le dossier est undifened
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  public uploader: FileUploader = new FileUploader({
    url: URL,
    isHTML5: true
  });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  uploadFile2(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      avatar: file
    });
    this.form.get('avatar').updateValueAndValidity();
    this.selectFile(event);
  }

  submitUser() {
    this.fileUploadService.addUser(
      this.form.value.name,
      this.form.value.avatar
    ).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('User successfully created!', event.body);
          setTimeout(() => {
            this.progress = 0;
          }, 1500);

      }
    })
  }


}


