<div class="container-fluid">
  <form [formGroup]="objectForm" (ngSubmit)="save()" id="childFormData">
    <div class="row col-10 offset-md-1 ">

      <!--buttons-->
      <div class="offset-md-10 container-fluid">
        <label class="btn-action" *ngIf="!edit && !dossier?.close">
          <button class="btn btn-secondary" (click)="modifier()"> Modifier</button>
        </label>
        <label class="btn-action" *ngIf="edit">
          <button class="btn btn-secondary" type="button" (click)="save()" [disabled]="!objectForm.valid">
            Enregistrer</button>
        </label>
        &nbsp;

        <label class="btn-action" *ngIf="edit">
          <button class="btn btn-secondary" type="button" (click)="openLinkDocument()"> Liens</button>
        </label>
      </div>
      <div class="col-6 p-4" *ngFor="let col of columns">

        <label for='name' *ngIf="col.type!='richtext'"><b>{{col.name}}</b>&nbsp;&nbsp;</label>

        <span *ngIf="(!edit || col.readonly ) && col.type!='date'"
          class="form-control">{{curMeta[col.prop][0]["value"]}}</span>
        <span *ngIf="(!edit|| col.readonly)  && col.type=='date'"
          class="form-control">{{(curMeta[col.prop][0]["value"])?(curMeta[col.prop][0]["value"] |
          date:"dd/MM/yyyy"):''}} </span>


        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='text'">

        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="number" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='number'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="date" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='date'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="time" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='time'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="tel" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='tel'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='email'">


        <select *ngIf="edit && col.type=='list' && col.list.display=='liste'"
          [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
          <option *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}</option>
        </select>
        <mat-radio-group *ngIf="edit && col.type=='list' && col.list.display=='radio'"
          [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
          <mat-radio-button *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}
          </mat-radio-button>&nbsp;&nbsp;
        </mat-radio-group>
      </div>
    </div>
    <div class="row col-8 offset-md-2" style="overflow: auto">

      <ng-container class='form-group' *ngFor="let col of columns">
        <div *ngIf="col.type=='richtext'">
          <label for='name'><b>{{col.name}}</b>&nbsp;&nbsp;</label>
          <ck-editor [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" name="editor1" skin="moono-lisa"
            language="fr" [fullPage]="true"></ck-editor>
        </div>
      </ng-container>
      <!--
  <div class="col-4"><app-timeline></app-timeline></div>-->
    </div>
    <div class="row col-10 offset-md-1 links-items" *ngFor="let link of links">
      <mat-accordion>
        <mat-expansion-panel [expanded]="false" (open)="getAllData(link)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ link.destinationParentName }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-action-row>
            <button mat-button type="button" (click)="getAllData(link)">Charger les données</button>
          </mat-action-row>
          <!--Début affichage des données-->
          <div class="example-container mat-elevation-z8 "
            *ngIf="curMeta['linkDetails'][link.destinationParentName]['columns'].length>0">
            <table mat-table #table [dataSource]="curMeta['linkDetails'][link.destinationParentName]['dataSource']">
              <!-- Name Column -->
              <ng-container *ngFor="let col of curMeta['linkDetails'][link.destinationParentName]['columns']"
                [matColumnDef]="col.prop">
                <th mat-header-cell *matHeaderCellDef> {{col.name}} </th>
                <td mat-cell *matCellDef="let row">
                  {{(col.type=='number')?(row[col.prop][0].value| number:'1.2-2':'fr') :
                  ((col.type=='date')?(row[col.prop][0].value | date:'dd/MM/yyyy'): row[col.prop][0].value )}}
                </td>
              </ng-container>
              <tr class="mat-row" *matNoDataRow>
                <!-- <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td> -->
                <td class="mat-cell" colspan="4">Aucun résultat pour le filter "{{input.value}}"</td>
              </tr>
              <tr mat-header-row
                *matHeaderRowDef="curMeta['linkDetails'][link.destinationParentName]['displayedColumns']"></tr>
              <tr mat-row
                *matRowDef="let row; columns: curMeta['linkDetails'][link.destinationParentName]['displayedColumns'];">
              </tr>
            </table>
          </div>

          <!--Fin affichage des données-->
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>

  <!--container-fluid-->
</div>