import { Component, OnInit, Input } from '@angular/core';
import { DocumentService } from '../../shared/services/document.service';
import { MatTableDataSource } from '@angular/material/table';
export interface TypeLink {
  destinationParentId: string;
  destinationParentName: string;
}
@Component({
  selector: 'app-onglet',
  templateUrl: './onglet.component.html',
  styleUrls: ['./onglet.component.scss']
})
export class OngletComponent implements OnInit {
  @Input() linkDetail: JSON;
  @Input() link: TypeLink;
  @Input() curMeta: JSON;
  docID: string;
  constructor(private documentService: DocumentService) { }

  ngOnInit(): void {
    this.docID = this.curMeta["documentuniqueid"];
  }
  getAllData(link: TypeLink) {
    console.log("link ====> ", link);
    this.documentService.getAllDocumentLinked(this.docID, link.destinationParentId)
      .subscribe({
        next: (sortie) => {
          console.log("sortie ==>", sortie)
          this.curMeta["linkDetails"][sortie["title"]] =
          {
            displayedColumns: [],
            columns: sortie["header"],
            dataSource: new MatTableDataSource<any>(sortie["data"])
          }
            ;
          for (var i = 0; i < sortie["header"].length; i++) {
            if (sortie["header"][i].visibleOnList) {
              this.curMeta["linkDetails"][sortie["title"]]["displayedColumns"].push(sortie["header"][i].prop);
            }
          }

        },
        error: (err) => {

        }
      })
  }
}
