import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RequestService } from '../../shared/services/request.service';
import { DossierService } from '../../shared/services/dossier.service';
import { DocumentService } from '../../shared/services/document.service';
import { Document, NewDocument } from '../../shared/model/document';
import { MatDialog } from '@angular/material/dialog';
import { ElkService } from 'src/app/shared/services/elk.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { Dossier } from 'src/app/shared/model/dossier';
import { DwtComponent } from '../dwt/dwt.component';
import { Result } from 'src/app/shared/data/result';
import { ToastrService } from 'ngx-toastr';
import Dynamsoft from 'dwt';
import { VisualizeComponent } from '../visualize/visualize.component';
import { MergeComponent } from '../merge/merge.component';
import { MoveDocComponent } from '../move-doc/move-doc.component';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadComponent } from '../upload/upload.component';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OperationComponent } from '../operation/operation.component';
import { ObjectDataCreateComponent } from '../object-data-create/object-data-create.component';

@Component({
  selector: 'app-tab-result',
  templateUrl: './tab-result.component.html',
  styleUrls: ['./tab-result.component.scss']
})
export class TabResultComponent implements OnInit {
  //.parse('[{"name":"Référence de dossier","prop":"ref_doss"},{"name":"Sexe","prop":"sexe"},{"name":"Date d\'inscription","prop":"date_incr"},{"name":"Age","prop":"age"},{"name":"Prénom","prop":"prenom"},{"name":"Nom","prop":"nom"}]');
  datas: any[] = []; //.parse('[{"ref_doss":"MD780937","sexe":"Masculin","date_incr":"","age":"24","prenom":"Alain","nom":"TESTE"}]');
  data: JSON;
  @Input() title: string;
  @Input() subtitle: string;
  dossier: Dossier;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading: boolean;

  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }


  @ViewChild('TABLE') table: ElementRef;
  @Input() dataSource: MatTableDataSource<Document[]>;
  @Input() displayedColumns;
  @Input() columns: JSON[];
  @Output() selectedRow: EventEmitter<Document> = new EventEmitter<Document>();
  @Input() columnTotal: any;
  scanAvailable: boolean = true;
  isAdmin: boolean;
  isCompta: boolean;
  isJournalBanque: boolean;
  selection = new SelectionModel<any>(true, []);
  @Output() docUploaded: EventEmitter<string> = new EventEmitter<string>();
  constructor(private requestService: RequestService,
    private dossierService: DossierService,
    private elkService: ElkService,
    private fileUploadService: FileUploadService,
    private documentService: DocumentService,
    private router: Router,
    private toaster: ToastrService,
    private activeRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    public dialog: MatDialog) { }
  tabUrl: string[] = [];
  ngOnInit(): void {
    //this.cdr.detectChanges()
    this.isAdmin = this.authService.user.roles.indexOf("admin") > -1;
    this.getTotalCost();
    this.setDossier();
    let checkScript = () => {

      if (Dynamsoft.Lib.detect.scriptLoaded) {
        var _that = this;
        Dynamsoft['_show_install_dialog'] = function () { _that.scanAvailable = false; }
        Dynamsoft.DWT.Load();

      } else {
        setTimeout(() => checkScript(), 100);
      }
    };
    checkScript();
  }
  setDossier() {
    this.dossierService.getDossierById(Number(this.dossierService.currentDossierId))
      .subscribe(doss => {
        this.dossier = doss;
        this.isCompta = this.columns.filter(value => value["name"] == "Etat comptable").length > 0;
        this.isJournalBanque = this.columns.filter(value => value["name"] == "Etat journalisation").length > 0;
      })
  }
  ngAfterViewInit() {
    this.setDataSourceAttributes()
    this.getTotalCost();
    setTimeout(() => { this.ngAfterViewInit(); }, 100);

  }
  open(row: any) {
    var i: number = this.requestService.requestDetail.indexOf(row);
    if (i <= 0 && this.requestService.requestDetail.length < 10) {
      this.requestService.requestDetail.push(row);
    }
    //console.log(row);
    this.selectedRow.emit(row);
    //emettre evenement vers parent

  }
  recodify() {
    this.isLoading = true;
    this.dossierService.recodify(this.dossier.dossierId).subscribe((res: Result) => {
      this.isLoading = false;
      this.toaster.success("La recodification est programmée avec succès! Veuillez rachaîchir le tableau dans quelques instants.");

    },
      error => {
        this.toaster.error("Une erreur s'est produite lors de la programmation de la recodification.");
        this.isLoading = false;
      });
  }
  compte(row: any) {
    var i: number = this.requestService.requestDetail.indexOf(row);
    if (i <= 0 && this.requestService.requestDetail.length < 10) {
      this.requestService.requestDetail.push(row);
    }
    //console.log(row);
    this.selectedRow.emit(row);
    //emettre evenement vers parent

  }





  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.getTotalCost(filterValue);
  }
  newdoc() {
    //console.log("dossierId=>",this.dossierService.currentDossierId);
    var document: NewDocument = new NewDocument(this.dossierService.currentDossierId);
    this.documentService.create(document).subscribe(doc => {
      this.datas.push(JSON.parse(this.toJsonString(doc)));
      this.open(this.datas[this.datas.length - 1]);
    })
    //this.selectedRow.emit(this.datas[this.datas.length-1]);
  }
  newdoc2() {
    var document: NewDocument = new NewDocument(this.dossierService.currentDossierId);
    this.documentService.create(document).subscribe(doc => {
      let dialogRef = this.dialog.open(ObjectDataCreateComponent, {
        data: { "curMeta": JSON.parse(this.toJsonString(doc)), "columns": this.columns, "dossier": this.dossier },
        disableClose: true,
        width: '60vw',
        height: '90vh'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    })
  }




  deletedoc(row: any) {
    //console.log("dossierId=>",this.dossierService.currentDossierId);
    console.log(row.documentuniqueid);
    if (confirm("Etes-vous sûr de vouloir supprimer  définivement ce document?")) {

      this.documentService.delete(row.documentuniqueid).subscribe((res: Result) => {
        this.toaster.success(res.message);
      },
        error => { this.toaster.error(error.message) })
    }
  }
  journalise(row: any) {
    if (confirm("Etes-vous sûr de vouloir journaliser ce relevé de compte? ")) {

      this.documentService.journal(row.documentuniqueid).subscribe((res: Result) => {
        this.toaster.success(res.message);
      },
        error => { this.toaster.error(error.message) })
    }
  }
  printdoc() {
    console.log("Datasource", this.dataSource.data);

    this.dataSource.data.forEach(element => {
      this.tabUrl.push(this.getFile(this.dossierService.currentDossierId, element["_id"]))
    });
  }

  getFile(index, docId): any {
    this.elkService.getFile(index, docId).subscribe(res => {
      var fileBlob = this.fileUploadService.dataURItoBlob(res["_source"]["data"], res["_source"]["attachment"]["content_type"]);
      return window.URL.createObjectURL(fileBlob);
    },
      error => {
        return "";
      })
  }
  printfiles() {


  }

  comptabilise(row) {
    let dialogRef = this.dialog.open(OperationComponent, {
      data: { "row": row, "dossier": this.dossier },
      disableClose: true,
      maxWidth: '60vw',
      maxHeight: '80vh',
      height: '100%',
      width: '100%',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  scanDocument() {
    let dialogRef = this.dialog.open(DwtComponent, {
      data: this.columns,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  visualizeDocument() {
    let dialogRef = this.dialog.open(VisualizeComponent, {
      data: { dossierId: this.dossierService.currentDossierId, data: this.selection ? this.selection.selected.reverse() : [] },
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  mergeDocument() {
    let dialogRef = this.dialog.open(MergeComponent, {
      data: { dossierId: this.dossierService.currentDossierId },
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '70vw',
      height: '100vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }



  uploadFiles() {

    let dialogRef = this.dialog.open(UploadComponent, {
      data: { dossierId: this.dossierService.currentDossierId },
      disableClose: true,
      width: '60vw',
      height: '90vh'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.docUploaded.emit();
      }
    });

  }
  /**
   * Refresh table
   */
  refresh() {
    this.docUploaded.emit();
  }
  reclasserDocuments() {
    let dialogRef = this.dialog.open(MoveDocComponent, {
      data: { "data": this.selection ? this.selection.selected.map(v => v.documentuniqueid) : [], "dossId": this.dossierService.currentDossierId },
      disableClose: true,
      width: '40vw',
      height: '50vh'
    });
    dialogRef.componentInstance.onModaleClose.subscribe(res => {
      this.redirectTo(res);
      dialogRef.close();
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  toJsonString(doc) {
    var tdoc: string = '{"documentuniqueid":"' + doc.documentuniqueid + '","_index":"dossier' + this.dossierService.currentDossierId + '"';
    doc.properties.forEach(element => {
      tdoc += ',"' + element.property.field + '":[{"id":' + element.id + ',"value":"' + element.value + '"}]'
    });
    tdoc += '}';
    return tdoc;
  }
  transform(value) {
    return value.map(item => JSON.parse(JSON.stringify(item)));
  }
  /** Gets the total cost of all transactions. */
  getTotalCost(filterValue?: string) {
    this.columnTotal = this.columns.filter(value => value["sommable"]);
    this.columnTotal.forEach(element => {
      element["position"] = this.dataSource?.data?.filter((item) => {
        var ret: boolean = false;
        if (filterValue == null) return true;
        this.columns.forEach(elt => {
          if (item[elt["prop"]][0].value != null) {
            ret = ret || (item[elt["prop"]][0].value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0);
          }
        });
        return ret;
      })
        .reduce((acc, line) => acc + +line[element["prop"]][0].value, 0);
    });
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Feuil1');

    /* save to file */
    XLSX.writeFile(wb, this.dossier.name + '.xlsx');

  }

  redirectTo(uuid: string) {
    const currentUrl = '/user/search/';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl, uuid]);
    });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      console.log("this.selection", this.selection)
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
    console.log("content select == ", this.selection)
  }
  matCheckboxChange(event, row) {
    if (event) {
      console.log(event)
      this.selection.toggle(row)
      console.log(this.selection)
    }
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
