<div class="container-fluid">
  <div class="row">
    <div class="col-12" style="padding: 0;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
          </mat-card-title>
          <mat-grid-list cols="10" rowHeight="50px" class="mat-card-header-text w-100">
            <mat-grid-tile [colspan]="7" class="mat-grid-titre">
            </mat-grid-tile>
            <!--buttons-->
            <mat-grid-tile [colspan]="3">

              <div class="btn-group d-flex justify-content-end" role="group"
                aria-label="Button group with nested dropdown" style="margin-right: 56px;">
                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="filter('ILAT')"><i
                    class="fa-solid fa-scanner"></i> ILAT</button>
                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="filter('ILC')"><i
                    class="fa-solid fa-scanner"></i> ILC</button>
                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="filter('ICC')"><i
                    class="fa-solid fa-scanner"></i> ICC</button>
                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="filter('IRL')"><i
                    class="fa-solid fa-scanner"></i> IRL</button>

              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-header>
        <mat-card-content>
          <!--/buttons-->
          <div class="vusadoc-container mat-elevation-z8 ">
            <mat-table [dataSource]="dataSource" class="w-100">
              <!-- Columns -->
              <ng-container matColumnDef="annee">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Année</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.annee}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="trimestre">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Trimestre</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.trimestre}}
                </mat-cell>
                <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
              </ng-container>

              <ng-container matColumnDef="valeur">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Valeur</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.value}}
                </mat-cell>
                <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
              </ng-container>

              <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Type</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.type}}
                </mat-cell>
                <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
              </ng-container>
              <ng-container matColumnDef="dateParution">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Date de parution</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.dateParution | date: 'dd/MM/YYYY'}}
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true "></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              <!-- Row shown when there is no matching data. -->
              <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr> -->
            </mat-table>
          </div>
        </mat-card-content>
        <mat-card-footer>

        </mat-card-footer>
      </mat-card>

    </div>
  </div>
</div>