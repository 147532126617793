<form [formGroup]="selectGroup" class="container-fluid" (submit)="selectionChange()">

    <h1 mat-dialog-title>Créer des lien entre le document ouvert et d'autres entités</h1>
    <div mat-dialog-content>
        <div class="row col-10">
            <div class="form-group col-8">
                <mat-form-field appearance="fill">
                    <mat-label>Sélectionnez un dossier</mat-label>
                    <mat-select formControlName="selected" #selected>
                        <mat-option *ngFor="let val of data" [value]="val">{{val.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button class="col-2" [disabled]="!selectGroup.valid" mat-stroked-button type="submit"
                color="primary">Chercher</button>
        </div>
        <div *ngFor="let doc of entries">{{doc.documentuniqueid}}</div>

        <div class="example-container mat-elevation-z8 " *ngIf="columns.length>0">
            <table mat-table #table [dataSource]="dataSource">

                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="matCheckboxChange($event, row)"
                            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container *ngFor="let col of columns" [matColumnDef]="col.prop">
                    <th mat-header-cell *matHeaderCellDef> {{col.name}} </th>
                    <td mat-cell *matCellDef="let row">
                        {{(col.type=='number')?(row[col.prop][0].value| number:'1.2-2':'fr') :
                        ((col.type=='date')?(row[col.prop][0].value | date:'dd/MM/yyyy'): row[col.prop][0].value )}}
                    </td>
                </ng-container>
                <tr class="mat-row" *matNoDataRow>
                    <!-- <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td> -->
                    <td class="mat-cell" colspan="4">Aucun résultat pour le filter "{{input.value}}"</td>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 500]" [length]="dataSource.data.length" [pageSize]="5"
                showFirstLastButtons></mat-paginator>
        </div>


    </div>

</form>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="createLink()" tabindex="2">Valider</button>
    <button mat-raised-button color="warn" (click)="close()" tabindex="-1">Fermer</button>
</div>