

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IndiceInsee } from '../../shared/model/indice-insee';
import { environment } from "../../../environments/environment";
import { AuthService } from './auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
@Injectable({
    providedIn: 'root'
})
export class IndiceService extends AuthService {
    getListIndiceByType(type: string) {
        return this.http.get<IndiceInsee[]>(`${environment.backendUrl}index/filter/${type}`, this.httpOptions);
    }
    constructor(public http: HttpClient, public oauthService: OAuthService) {
        super(oauthService, http);
    }

    /**
     *  GET liste indices
     * @returns 
     */
    getListIndice() {
        return this.http.get<IndiceInsee[]>(`${environment.backendUrl}index/all`, this.httpOptions)
    }
    /**
     * Création d'un indice
     * @param indicesInsee 
     * @returns 
     */
    createIndice(indicesInsee: IndiceInsee) {
        return this.http.post(`${environment.backendUrl}index/create`, indicesInsee, this.httpOptions);
    }
}