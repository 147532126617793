<!--Début affichage des données-->
<div class="example-container mat-elevation-z8 " *ngIf="linkDetail['columns'].length>0">
    <table mat-table #table [dataSource]="linkDetail['dataSource']">
        <!-- Name Column -->
        <ng-container *ngFor="let col of linkDetail['columns']" [matColumnDef]="col.prop">
            <th mat-header-cell *matHeaderCellDef> {{col.name}} </th>
            <td mat-cell *matCellDef="let row">
                {{(col.type=='number')?(row[col.prop][0].value| number:'1.2-2':'fr') :
                ((col.type=='date')?(row[col.prop][0].value | date:'dd/MM/yyyy'): row[col.prop][0].value )}}
            </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
            <!-- <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td> -->
            <td class="mat-cell" colspan="4">Aucun résultat pour le filter "{{input.value}}"</td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="linkDetail['displayedColumns']"></tr>
        <tr mat-row *matRowDef="let row; columns: linkDetail['displayedColumns'];">
        </tr>
    </table>
</div>
<mat-action-row>
    <button mat-button type="button" (click)="getAllData(link)">Charger les données</button>
</mat-action-row>