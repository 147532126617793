import { Dossier } from "../model/dossier"
import { MetaData } from '../model/metadata';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { headersToString } from "selenium-webdriver/http";
import { OAuthService } from "angular-oauth2-oidc";
import { AuthService } from "./auth.service";
import { Result } from "../data/result";

@Injectable()
export class DossierService extends AuthService {


  dossiers: Dossier[] = [];
  metadatas: MetaData[] = [];
  currentDossierId: string;
  dossierUUID: string;
  widgetData$: BehaviorSubject<Dossier[]> = new BehaviorSubject(null);
  icons: string[] = [
    'activity',
    'airplay',
    'alert-circle',
    'alert-octagon',
    'alert-triangle',
    'align-center',
    'align-justify',
    'align-left',
    'align-right',
    'anchor',
    'aperture',
    'archive',
    'arrow-down-circle',
    'arrow-down-left',
    'arrow-down-right',
    'arrow-down',
    'arrow-left-circle',
    'arrow-left',
    'arrow-right-circle',
    'arrow-right',
    'arrow-up-circle',
    'arrow-up-left',
    'arrow-up-right',
    'arrow-up',
    'at-sign',
    'award',
    'bar-chart-2',
    'bar-chart',
    'battery-charging',
    'battery',
    'bell-off',
    'bell',
    'bluetooth',
    'bold',
    'book-open',
    'book',
    'bookmark',
    'box',
    'briefcase',
    'calendar',
    'camera-off',
    'camera',
    'cast',
    'check-circle',
    'check-square',
    'check',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'chevrons-down',
    'chevrons-left',
    'chevrons-right',
    'chevrons-up',
    'chrome',
    'circle',
    'clipboard',
    'clock',
    'cloud-drizzle',
    'cloud-lightning',
    'cloud-off',
    'cloud-rain',
    'cloud-snow',
    'cloud',
    'code',
    'codepen',
    'coffee',
    'command',
    'compass',
    'copy',
    'corner-down-left',
    'corner-down-right',
    'corner-left-down',
    'corner-left-up',
    'corner-right-down',
    'corner-right-up',
    'corner-up-left',
    'corner-up-right',
    'cpu',
    'credit-card',
    'crop',
    'crosshair',
    'database',
    'delete',
    'disc',
    'dollar-sign',
    'download-cloud',
    'download',
    'droplet',
    'edit-2',
    'edit-3',
    'edit',
    'external-link',
    'eye-off',
    'eye',
    'facebook',
    'fast-forward',
    'feather',
    'file-minus',
    'file-plus',
    'file-text',
    'file',
    'film',
    'filter',
    'flag',
    'folder-minus',
    'folder-plus',
    'folder',
    'gift',
    'git-branch',
    'git-commit',
    'git-merge',
    'git-pull-request',
    'github',
    'gitlab',
    'globe',
    'grid',
    'hard-drive',
    'hash',
    'headphones',
    'heart',
    'help-circle',
    'home',
    'image',
    'inbox',
    'info',
    'instagram',
    'italic',
    'layers',
    'layout',
    'life-buoy',
    'link-2',
    'link',
    'linkedin',
    'list',
    'loader',
    'lock',
    'log-in',
    'log-out',
    'mail',
    'map-pin',
    'map',
    'maximize-2',
    'maximize',
    'menu',
    'message-circle',
    'message-square',
    'mic-off',
    'mic',
    'minimize-2',
    'minimize',
    'minus-circle',
    'minus-square',
    'minus',
    'monitor',
    'moon',
    'more-horizontal',
    'more-vertical',
    'move',
    'music',
    'navigation-2',
    'navigation',
    'octagon',
    'package',
    'paperclip',
    'pause-circle',
    'pause',
    'percent',
    'phone-call',
    'phone-forwarded',
    'phone-incoming',
    'phone-missed',
    'phone-off',
    'phone-outgoing',
    'phone',
    'pie-chart',
    'play-circle',
    'play',
    'plus-circle',
    'plus-square',
    'plus',
    'pocket',
    'power',
    'printer',
    'radio',
    'refresh-ccw',
    'refresh-cw',
    'repeat',
    'rewind',
    'rotate-ccw',
    'rotate-cw',
    'rss',
    'save',
    'scissors',
    'search',
    'send',
    'server',
    'settings',
    'share-2',
    'share',
    'shield-off',
    'shield',
    'shopping-bag',
    'shopping-cart',
    'shuffle',
    'sidebar',
    'skip-back',
    'skip-forward',
    'slack',
    'slash',
    'sliders',
    'smartphone',
    'speaker',
    'square',
    'star',
    'stop-circle',
    'sun',
    'sunrise',
    'sunset',
    'tablet',
    'tag',
    'target',
    'terminal',
    'thermometer',
    'thumbs-down',
    'thumbs-up',
    'toggle-left',
    'toggle-right',
    'trash-2',
    'trash',
    'trending-down',
    'trending-up',
    'triangle',
    'truck',
    'tv',
    'twitter',
    'type',
    'umbrella',
    'underline',
    'unlock',
    'upload-cloud',
    'upload',
    'user-check',
    'user-minus',
    'user-plus',
    'user-x',
    'user',
    'users',
    'video-off',
    'video',
    'voicemail',
    'volume-1',
    'volume-2',
    'volume-x',
    'volume',
    'watch',
    'wifi-off',
    'wifi',
    'wind',
    'x-circle',
    'x-square',
    'x',
    'youtube',
    'zap-off',
    'zap',
    'zoom-in',
    'zoom-out'
  ];
  constructor(public http: HttpClient,
    public oauthService: OAuthService) {
    super(oauthService, http);
  }

  getDossiersByParent(id: number, user: any): Observable<Dossier[]> {

    return this.http.post<Dossier[]>(environment.backendUrl + 'dossier/parent/' + id, user, this.httpOptions);
  }
  getDossiersByParentSide(id: number, user: any): Observable<Dossier[]> {

    return this.http.post<Dossier[]>(environment.backendUrl + 'dossier/parentside/' + id, user, this.httpOptions);
  }
  getTemplateByParent(id: number): Observable<Dossier[]> {
    return this.http.get<Dossier[]>(environment.backendUrl + 'dossier/template/' + id, this.httpOptions);
  }

  getDossierByUuid(id: string): Observable<Dossier> {
    return this.http.get<Dossier>(environment.backendUrl + 'dossier/uuid/' + id, this.httpOptions);
  }

  getArbo(id: number): Observable<Dossier> {
    return this.http.get<Dossier>(environment.backendUrl + 'dossier/arbo/' + id, this.httpOptions);
  }

  recodify(dossierId: number): Observable<Result> {
    return this.http.get<Result>(environment.backendUrl + 'dossier/recodify/' + dossierId, this.httpOptions);
  }

  getDossierById(id: number): Observable<Dossier> {
    return this.http.get<Dossier>(environment.backendUrl + 'dossier/' + id, this.httpOptions);

  }
  /**
   * Save dossier
   * @param dossier 
   */
  save(dossier: Dossier): Observable<Dossier> {
    return this.http.post<Dossier>(environment.backendUrl + 'dossier/save/', dossier, this.httpOptions);
  }

  duplicate(dossier: Dossier, uuid: string): Observable<Dossier> {
    return this.http.post<Dossier>(environment.backendUrl + 'dossier/duplicate/' + uuid, dossier, this.httpOptions);
  }

  updateDossier(dossier: Dossier) {

    return this.http.put<Dossier>(`${environment.backendUrl}dossier/${dossier.dossierId}/update/`, dossier, this.httpOptions);
  }


  merge(dossierId: number) {
    return this.http.get<string>(`${environment.backendUrl}dossier/merge/${dossierId}/`, this.httpOptions);
  }

  removeDossier(doss: Dossier): Observable<Dossier[]> {
    console.log(this.httpOptions);
    return this.http.get<Dossier[]>(`${environment.backendUrl}dossier/remove/${doss.dossierId}`, this.httpOptions);

  }

  createnewdossier(dossier: Dossier): Observable<Dossier> {
    return this.http.post<Dossier>(environment.backendUrl + 'dossier/newinstance/' + dossier.parentId, dossier, this.httpOptions);
  }
  /**
   * Permet de reclasser un document
   * @param idDocs 
   * @param indexElk 
   * @param uuidCible 
   * @returns 
   */
  moveDossierTo(idDocs: string[], indexElk: string, uuidCible: string) {
    return this.http.post<string>(`${environment.backendUrl}dossier/moveto/${indexElk}/${uuidCible}`, idDocs, this.httpOptions);
  }
  /**
   * 
   * @param docId
   */
  getDossierBrothers(docId: string): Observable<Dossier[]>{
    return this.http.get<Dossier[]>(`${environment.backendUrl}dossier/data/${docId}`, this.httpOptions);
  }

  getMetadataByParent(id: number): Observable<MetaData[]> {
    /*var dsi: MetaData[]=[];

    for (var i=0;i<this.metadatas.length;i++){
      if (this.metadatas[i].fk_obj_id==id){
        dsi.push(this.metadatas[i]);
      }
    }
    return dsi;*/
    return this.http.get<MetaData[]>(environment.backendUrl + 'dossier/' + id + '/properties/', this.httpOptions);

  }

  setWidgetData(value: Dossier[]) {
    this.widgetData$.next(value);
  }
  getWidgetData(): Observable<Dossier[]> {
    return this.widgetData$.asObservable();
  }
}
