<div class="container-fluid">
  <form [formGroup]="objectForm" (ngSubmit)="save()" id="childFormData">
    <div class="row col-10 offset-md-1 ">

      <!--buttons-->
      <div class="offset-md-10 container-fluid">
        <label class="btn-action" *ngIf="!edit && !dossier?.close">
          <button class="btn btn-secondary" (click)="modifier()"> Modifier</button>
        </label>
        <label class="btn-action" *ngIf="edit">
          <button class="btn btn-secondary" type="button" (click)="save()" [disabled]="!objectForm.valid">
            Enregistrer</button>
        </label>
        &nbsp;

        <label class="btn-action" *ngIf="edit">
          <button class="btn btn-secondary" type="button" (click)="openLinkDocument()"> Liens</button>
        </label>
      </div>
      <div class="col-6 p-4" *ngFor="let col of columns">

        <label for='name' *ngIf="col.type!='richtext'"><b>{{col.name}}</b>&nbsp;&nbsp;</label>

        <span *ngIf="(!edit || col.readonly ) && col.type!='date'"
          class="form-control">{{curMeta[col.prop][0]["value"]}}</span>
        <span *ngIf="(!edit|| col.readonly)  && col.type=='date'"
          class="form-control">{{(curMeta[col.prop][0]["value"])?(curMeta[col.prop][0]["value"] |
          date:"dd/MM/yyyy"):''}} </span>


        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='text'">

        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="number" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='number'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="date" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='date'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="time" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='time'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="tel" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='tel'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
          *ngIf="!col.readonly && edit && col.type=='email'">


        <select *ngIf="edit && col.type=='list' && col.list.display=='liste'"
          [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
          <option *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}</option>
        </select>
        <mat-radio-group *ngIf="edit && col.type=='list' && col.list.display=='radio'"
          [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
          <mat-radio-button *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}
          </mat-radio-button>&nbsp;&nbsp;
        </mat-radio-group>
      </div>
    </div>
    <div class="row col-8 offset-md-2" style="overflow: auto">

      <ng-container class='form-group' *ngFor="let col of columns">
        <div *ngIf="col.type=='richtext'">
          <label for='name'><b>{{col.name}}</b>&nbsp;&nbsp;</label>
          <ck-editor [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" name="editor1" skin="moono-lisa"
            language="fr" [fullPage]="true"></ck-editor>
        </div>
      </ng-container>
      <!--
  <div class="col-4"><app-timeline></app-timeline></div>-->
    </div>
    <mat-tab-group class="groupe search-tab col-10 offset-md-1" animationDuration="0ms" style="padding:0px;">
      <ng-container *ngFor="let link of links">
        <mat-tab label="{{link.destinationParentName}}" style="padding:0px;">
          <app-onglet [link]="link" [linkDetail]="curMeta['linkDetails'][link.destinationParentName]"
            [curMeta]="curMeta">
          </app-onglet>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </form>
</div>