import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, Inject } from '@angular/core';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { PropertyValue } from '../../shared/model/propertyvalue';
import { Document } from '../../shared/model/document';
import { DocumentService } from '../../shared/services/document.service';
import { ToastrService } from 'ngx-toastr';
import { RequestService } from '../../shared/services/request.service';
import { ElkService } from '../../shared/services/elk.service';
import { SearchHit } from '../../shared/model/searchResult';
import { Dossier } from '../../shared/model/dossier';
import { DossierService } from '../../shared/services/dossier.service';
import { FileUploadService } from '../../shared/services/file-upload.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SendFileComponent } from '../send-file/send-file.component';
import { MailMessage } from '../../shared/model/mail.message';
import { FileUploader } from 'ng2-file-upload';
import { getConfigFileParsingDiagnostics } from 'typescript';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChatService } from '../../shared/services/chat.service';
import { AuthService } from '../../shared/services/auth.service';
import { getMatFormFieldDuplicatedHintError } from '@angular/material/form-field';
import { LinkDocumentComponent } from '../link-document/link-document.component';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-object-data-create',
  templateUrl: './object-data-create.component.html',
  styleUrls: ['./object-data-create.component.scss']
})
export class ObjectDataCreateComponent implements OnInit {

  curMeta: JSON;
  columns: JSON[];
  dossier: Dossier;
  @Output() removeEvent: EventEmitter<any> = new EventEmitter();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  edit: boolean = true;
  document: Document;
  propertyValue: PropertyValue;
  public objectForm: FormGroup;
  supplierEmail: string;

  form: FormGroup;
  progress: number = 0;
  public docID: string;
  public files: FileList;
  public index: string;
  viewer: string = null;

  public fileUrl;
  public fileBlob;
  private doc: JSON;
  uploading: boolean = false;
  fileSize: any;
  errorMessage: string;
  disable: string;
  sizeError: boolean;
  panelOpenState: boolean = false;
  customerEmail: string;
  messages: any = [];
  Formulas: string[] = ['@Supplier', '@Name'];
  brothers: Dossier[];
  dataLink: any[] = [];
  constructor(private fb: FormBuilder, private documentService: DocumentService,
    private toastrService: ToastrService,
    private requestService: RequestService,
    private dossierService: DossierService,
    private elkService: ElkService,
    private chatService: ChatService,
    private authService: AuthService,
    private fileUploadService: FileUploadService,
    public dialogRef: MatDialogRef<LinkDocumentComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) {
    this.curMeta = data.curMeta;
    this.columns = data.columns;
    this.dossier = data.dossier;
    this.form = this.fb.group({
      name: [''],
      avatar: [null]
    })
  }
  public config: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null
  };
  ngOnInit(): void {
    this.document = new Document(this.dossierService.currentDossierId);

    this.files = null;
    this.docID = this.curMeta["documentuniqueid"];
    this.index = this.curMeta["_index"];
    this.initForm();
  }

  downloadFile() {
    window.open(this.fileUrl);
    //this.file = new File([this.fileBlob], this.fileName, { type: "application/pdf" });
  }

  save() {
    this.document.documentuniqueid = this.curMeta["documentuniqueid"];
    for (var i = 0; i < this.columns.length; i++) {
      this.document.properties.push(new PropertyValue(
        this.curMeta[this.columns[i]["prop"]][0]["id"],
        this.objectForm.value[
        this.columns[i]["prop"] + '_'
        + this.curMeta[this.columns[i]["prop"]][0]["id"]
        ], this.columns[i]["propertyId"]
      ));
    }
    this.documentService.update(this.document).subscribe(res => {
      this.toastrService.success("Document sauvegardé avec succès.");
      this.dialogRef.close(false);

    });
    //this.document.properties.push(new PropertyValue(null,this.objectForm.value[i].,);
  }
  public onUploadInit(args: any): void { }
  close() {
    this.removeEvent.emit(this.curMeta);
    this.dialogRef.close(false);
  }
  public initForm() {
    this.objectForm = new FormGroup({});
    var formControl: FormControl;
    // var jsonString: string = '{';
    var sep = "";
    var validators: ValidatorFn[] = [];
    for (var i = 0; i < this.columns.length; i++) {
      validators.splice(0, validators.length);
      if (this.columns[i]["required"]) {
        validators.push(Validators.required);
      }
      if (this.columns[i]["hasmin"]) {
        validators.push(Validators.min(this.columns[i]["minvalue"]));
      }
      if (this.columns[i]["hasmax"]) {
        validators.push(Validators.max(this.columns[i]["maxvalue"]));
      }

      if (this.columns[i]["pattern"] && this.Formulas.indexOf(this.columns[i]["pattern"]) < 0) {
        validators.push(Validators.pattern(this.columns[i]["pattern"]));
      }
      if (this.columns[i]["type"] == "email") {
        validators.push(Validators.email);
      }
      formControl = new FormControl(this.curMeta[this.columns[i]["prop"]][0]["value"], validators);
      this.objectForm.addControl(this.columns[i]["prop"] + '_'
        + this.curMeta[this.columns[i]["prop"]][0]["id"], formControl);

      if (this.columns[i]["type"] == 'text' && this.columns[i]["name"] == 'Email') {
        this.customerEmail = this.columns[i]["prop"] + '_'
          + this.curMeta[this.columns[i]["prop"]][0]["id"];
      }
    }
  }

}
