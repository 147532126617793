import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IndiceService } from '../../shared/services/indice.service';
import { IndiceInsee } from '../../shared/model/indice-insee';
import { TypeInseeEnum } from "../../shared/model/type-insee.enum";
import { MatDialog } from '@angular/material/dialog';
import { IndiceComponent } from './indice/indice.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-manage-indice',
  templateUrl: './manage-indice.component.html',
  styleUrls: ['./manage-indice.component.scss']
})
export class ManageIndiceComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['annee', 'trimestre', 'valeur', 'type', 'dateParution'];


  indicesInsee: IndiceInsee[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>(this.indicesInsee);;

  constructor(private indiceService: IndiceService, public dialog: MatDialog) {

  }

  ngOnInit(): void {

    this.filter('ILAT');

  }
  getListInsee() {
    this.indiceService.getListIndice()
      .subscribe((sortie) => {
        this.indicesInsee = sortie;
        this.dataSource = new MatTableDataSource<any>(this.indicesInsee);
      });
  }
  ngAfterViewInit() {

  }

  newIndice() {

    const dialogRef = this.dialog.open(IndiceComponent, {
      width: '500px',
      data: {
        user: null,
        mode: ''
      }
    })
    dialogRef.afterClosed()
      .subscribe((isOk) => {
        this.getListInsee();
      })
  }
  applyFilter(event) {

  }
  refresh() {
    this.getListInsee();
  }
  filter(type: string) {
    switch (type) {
      case 'ILAT': this.indiceService.getListIndiceByType('ILAT')
        .subscribe((sortie) => {
          this.indicesInsee = sortie;
          this.dataSource = new MatTableDataSource<any>(this.indicesInsee);
        })
        break;
      case 'ILC': this.indiceService.getListIndiceByType('ILC')
        .subscribe((sortie) => {
          this.indicesInsee = sortie;
          this.dataSource = new MatTableDataSource<any>(this.indicesInsee);
        })
        break;
      case 'IRL': this.indiceService.getListIndiceByType('IRL')
        .subscribe((sortie) => {
          this.indicesInsee = sortie;
          this.dataSource = new MatTableDataSource<any>(this.indicesInsee);
        })
        break;
      case 'ICC': this.indiceService.getListIndiceByType('ICC')
        .subscribe((sortie) => {
          this.indicesInsee = sortie;
          this.dataSource = new MatTableDataSource<any>(this.indicesInsee);
        })
        break;
      case 'ALL': this.getListInsee();
        break;
    }

  }
  open() {

  }
}
